


















import {Component} from 'vue-property-decorator';
import {readExercises} from '@/store/crud/getters';
import {dispatchDeleteExercise, dispatchGetExercises} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import ExerciseTable from '@/components/tables/ExerciseTable.vue';

@Component({components: {ConfirmationModal, ExerciseTable}})
export default class Exercise extends AppComponent {
  get exercises() {
    const exercises = readExercises(this.$store);
    if (exercises) {
      return exercises;
    } else {
      return [];
    }
  }
  public fetchingExercisesData = false;
  /**
   * created hook, it only calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  /**
   * fetches needed data from the store/ server and displays
   * loading spinner
   */
  public async fetchData() {
    this.fetchingExercisesData = true;
    await this.$nextTick();
    await dispatchGetExercises(this.$store);
    this.fetchingExercisesData = false;
  }

  /**
   * Delete a exercise and refresh the screen
   */
  public async deleteExercise(exerciseId: string) {
    await dispatchDeleteExercise(this.$store, {exerciseId});
    this.fetchData();
  }
}
